import { defineComponent } from 'vue';
export default defineComponent({
    name: 'Input',
    props: {
        type: {
            type: String,
            default: "text"
        },
        placeholder: {
            type: String,
            default: " "
        },
        label: {
            type: String,
            default: null
        },
        disabled: {
            type: Boolean,
            default: false
        },
        modelValue: [String, Number],
        min: String,
        max: Number,
    },
    emits: ['update:modelValue', 'blur', 'updateAfter1000Ms'],
    watch: {
        value: function (newValue) {
            this.$emit('update:modelValue', newValue);
        }
    },
    computed: {
        value: {
            get: function () {
                return this.modelValue;
            },
            set: function (newValue) {
                this.isUpdating = true;
                newValue = this.checkMaxValue(newValue);
                this.$emit('update:modelValue', newValue);
                var vm = this;
                setTimeout(function () {
                    if (vm.isUpdating) {
                        vm.isUpdating = false;
                        vm.$emit('updateAfter1000Ms');
                    }
                }, 1000);
            }
        }
    },
    methods: {
        checkMaxValue: function (value) {
            if (!this.max)
                return value;
            if (value > this.max)
                return this.max;
            return value;
        }
    },
    data: function () {
        return {
            isUpdating: false
        };
    }
});
