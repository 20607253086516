<template>
    <div class="WatFocusConnectivityTest">
        <div class="WatFocusConnectivityTest--Steps">
            <div class="WatFocusConnectivityTest--Step" v-for="x in 5" :class="{'completed':x < currentStep, 'active':x == currentStep}">
                <svg width="11" height="9" viewBox="0 0 11 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1.125 5.125L3.625 7.625L9.875 1.375" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
            </div>
        </div>

        <div class="WatFocusConnectivityTest--Test" v-if="currentStep == 1">
            <svg width="87" height="79" viewBox="0 0 87 79" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M54.4095 13.7956C54.4095 7.77064 49.5253 2.88647 43.5004 2.88647C37.4755 2.88647 32.5913 7.77064 32.5913 13.7956V35.6137C32.5913 41.6387 37.4755 46.5228 43.5004 46.5228C49.5253 46.5228 54.4095 41.6387 54.4095 35.6137V13.7956Z" stroke="url(#paint0_linear_4210_9258)" stroke-width="5.45455"/>
                <path d="M18.0455 6.52284V13.7956M3.5 2.88647V17.4319M68.9545 6.52284V13.7956M83.5 2.88647V17.4319M18.0455 31.9774V35.6137C18.0455 42.3647 20.7273 48.8392 25.5009 53.6128C30.2746 58.3865 36.749 61.0683 43.5 61.0683M43.5 61.0683C50.251 61.0683 56.7254 58.3865 61.4991 53.6128C66.2727 48.8392 68.9545 42.3647 68.9545 35.6137V31.9774M43.5 61.0683V75.6137M43.5 75.6137H32.5909M43.5 75.6137H54.4091" stroke="#2D0077" stroke-width="5.45455" stroke-linecap="round" stroke-linejoin="round"/>
                <defs>
                <linearGradient id="paint0_linear_4210_9258" x1="32.5913" y1="24.7047" x2="54.4095" y2="24.7047" gradientUnits="userSpaceOnUse">
                <stop stop-color="#FF1564"/>
                <stop offset="1" stop-color="#FD7328"/>
                </linearGradient>
                </defs>
            </svg>
            <h3>{{ $t('wat_focus_connectivity_micro') }}</h3>
            <h4>{{ devices.micro.name }}</h4>
            <div class="WatFocusConnectivityTest--Volume">
                <span v-for="x in 12" :class="{'active':microVolume > (100 / 12) * x}"></span>
            </div>
        </div>
        <div class="WatFocusConnectivityTest--Test" v-if="currentStep == 2">
            <svg width="81" height="81" viewBox="0 0 81 81" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M70.5 63.0886H43V57.9636C49.3816 57.3222 55.2726 54.2527 59.4546 49.3899C63.6366 44.5271 65.7897 38.243 65.4687 31.8373C65.1476 25.4316 62.3768 19.3943 57.7295 14.9741C53.0822 10.5539 46.9137 8.08887 40.5 8.08887C34.0863 8.08887 27.9178 10.5539 23.2705 14.9741C18.6232 19.3943 15.8524 25.4316 15.5313 31.8373C15.2103 38.243 17.3634 44.5271 21.5454 49.3899C25.7274 54.2527 31.6184 57.3222 38 57.9636V63.0886H10.5C9.83696 63.0886 9.20107 63.3519 8.73223 63.8208C8.26339 64.2896 8 64.9255 8 65.5886C8 66.2516 8.26339 66.8875 8.73223 67.3563C9.20107 67.8252 9.83696 68.0885 10.5 68.0885H70.5C71.163 68.0885 71.7989 67.8252 72.2678 67.3563C72.7366 66.8875 73 66.2516 73 65.5886C73 64.9255 72.7366 64.2896 72.2678 63.8208C71.7989 63.3519 71.163 63.0886 70.5 63.0886ZM20.5 33.0886C20.5 29.1329 21.673 25.2661 23.8706 21.9771C26.0682 18.6882 29.1918 16.1247 32.8463 14.611C36.5009 13.0972 40.5222 12.7011 44.4018 13.4728C48.2814 14.2446 51.8451 16.1494 54.6421 18.9464C57.4392 21.7435 59.344 25.3071 60.1157 29.1867C60.8874 33.0664 60.4913 37.0877 58.9776 40.7422C57.4638 44.3967 54.9004 47.5203 51.6114 49.7179C48.3224 51.9156 44.4556 53.0886 40.5 53.0886C35.1974 53.0828 30.1137 50.9738 26.3643 47.2243C22.6148 43.4748 20.5058 38.3911 20.5 33.0886Z" fill="#2D0077"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M53.3789 32.7471C53.3789 30.2748 52.6458 27.8581 51.2723 25.8024C49.8988 23.7468 47.9465 22.1447 45.6625 21.1986C43.3784 20.2525 40.865 20.0049 38.4403 20.4873C36.0155 20.9696 33.7882 22.1601 32.0401 23.9082C30.2919 25.6564 29.1014 27.8837 28.6191 30.3084C28.1368 32.7332 28.3843 35.2465 29.3304 37.5306C30.2765 39.8147 31.8787 41.7669 33.9343 43.1404C35.9899 44.514 38.4066 45.2471 40.8789 45.2471C44.1941 45.2471 47.3735 43.9301 49.7177 41.5859C52.062 39.2417 53.3789 36.0623 53.3789 32.7471ZM41.1061 40.8531C45.4575 40.8531 48.9849 37.3257 48.9849 32.9743C48.9849 28.623 45.4575 25.0956 41.1061 25.0956C36.7548 25.0956 33.2273 28.623 33.2273 32.9743C33.2273 37.3257 36.7548 40.8531 41.1061 40.8531Z" fill="url(#paint0_linear_4210_9287)"/>
                <defs>
                <linearGradient id="paint0_linear_4210_9287" x1="28.3789" y1="32.7471" x2="53.3789" y2="32.7471" gradientUnits="userSpaceOnUse">
                <stop stop-color="#FF1564"/>
                <stop offset="1" stop-color="#FD7328"/>
                </linearGradient>
                </defs>
            </svg>
            <h3>{{ $t('wat_focus_connectivity_camera') }}</h3>
            <h4>{{ devices.camera.name }}</h4>
            <video ref="video" width="640" height="480" autoplay playsinline></video>
        </div>
        <div class="WatFocusConnectivityTest--Test" v-if="currentStep == 3">
            <svg width="81" height="80" viewBox="0 0 81 80" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M65.5 13.125H15.5C13.6766 13.125 11.928 13.8493 10.6386 15.1386C9.34933 16.428 8.625 18.1766 8.625 20V55C8.625 56.8234 9.34933 58.572 10.6386 59.8614C11.928 61.1507 13.6766 61.875 15.5 61.875H65.5C67.3234 61.875 69.072 61.1507 70.3614 59.8614C71.6507 58.572 72.375 56.8234 72.375 55V20C72.375 18.1766 71.6507 16.428 70.3614 15.1386C69.072 13.8493 67.3234 13.125 65.5 13.125ZM68.625 55C68.625 55.8288 68.2958 56.6237 67.7097 57.2097C67.1237 57.7958 66.3288 58.125 65.5 58.125H15.5C14.6712 58.125 13.8763 57.7958 13.2903 57.2097C12.7042 56.6237 12.375 55.8288 12.375 55V20C12.375 19.1712 12.7042 18.3763 13.2903 17.7903C13.8763 17.2042 14.6712 16.875 15.5 16.875H65.5C66.3288 16.875 67.1237 17.2042 67.7097 17.7903C68.2958 18.3763 68.625 19.1712 68.625 20V55ZM52.375 70C52.375 70.4973 52.1775 70.9742 51.8258 71.3258C51.4742 71.6775 50.9973 71.875 50.5 71.875H30.5C30.0027 71.875 29.5258 71.6775 29.1742 71.3258C28.8225 70.9742 28.625 70.4973 28.625 70C28.625 69.5027 28.8225 69.0258 29.1742 68.6742C29.5258 68.3225 30.0027 68.125 30.5 68.125H50.5C50.9973 68.125 51.4742 68.3225 51.8258 68.6742C52.1775 69.0258 52.375 69.5027 52.375 70Z" fill="#2D0077"/>
                <path d="M49.7596 34.284C49.6571 34.054 49.5093 33.847 49.3251 33.6753L41.8251 26.1753C41.4735 25.8242 40.997 25.627 40.5001 25.627C40.0032 25.627 39.5267 25.8242 39.1751 26.1753L31.6751 33.6753C31.3439 34.0307 31.1636 34.5009 31.1722 34.9866C31.1807 35.4724 31.3775 35.9358 31.7211 36.2794C32.0646 36.6229 32.528 36.8197 33.0138 36.8282C33.4996 36.8368 33.9697 36.6565 34.3251 36.3253L38.6251 32.0284V47.5003C38.6251 47.9976 38.8226 48.4745 39.1743 48.8261C39.5259 49.1778 40.0028 49.3753 40.5001 49.3753C40.9974 49.3753 41.4743 49.1778 41.8259 48.8261C42.1776 48.4745 42.3751 47.9976 42.3751 47.5003V32.0284L46.6751 36.3253C46.8468 36.5095 47.0538 36.6573 47.2838 36.7598C47.5138 36.8622 47.762 36.9173 48.0138 36.9218C48.2656 36.9262 48.5156 36.8799 48.7491 36.7856C48.9826 36.6913 49.1947 36.5509 49.3727 36.3729C49.5507 36.1949 49.6911 35.9828 49.7854 35.7493C49.8797 35.5158 49.926 35.2658 49.9216 35.014C49.9171 34.7622 49.862 34.514 49.7596 34.284Z" fill="url(#paint0_linear_4295_57806)"/>
                <defs>
                <linearGradient id="paint0_linear_4295_57806" x1="31.1719" y1="37.5011" x2="49.9219" y2="37.5011" gradientUnits="userSpaceOnUse">
                <stop stop-color="#FF1564"/>
                <stop offset="1" stop-color="#FD7328"/>
                </linearGradient>
                </defs>
            </svg>
            <h3>{{ $t('wat_focus_connectivity_screen') }}</h3>
            <button v-on:click="shareScreen()">
                {{ shareScreenText }}
            </button>
        </div>
        <div class="WatFocusConnectivityTest--Test" v-if="currentStep == 4">
            <svg width="81" height="80" viewBox="0 0 81 80" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M21.6665 51.1672L18.2565 47.7172C21.1498 44.8239 24.4876 42.5583 28.2698 40.9205C32.0521 39.2828 36.1321 38.4628 40.5098 38.4605C44.8876 38.4583 48.9654 39.2805 52.7432 40.9272C56.521 42.5739 59.8543 44.8505 62.7432 47.7572L59.3332 51.1672C56.8887 48.7228 54.0554 46.8061 50.8332 45.4172C47.611 44.0283 44.1665 43.3339 40.4998 43.3339C36.8332 43.3339 33.3887 44.0283 30.1665 45.4172C26.9443 46.8061 24.111 48.7228 21.6665 51.1672ZM7.49984 37.0005L4.08984 33.5905C8.72984 28.9239 14.1465 25.235 20.3398 22.5239C26.5332 19.8128 33.2532 18.4594 40.4998 18.4639C47.7465 18.4683 54.4665 19.8239 60.6598 22.5305C66.8532 25.2372 72.2699 28.925 76.9099 33.5939L73.4998 37.0005C69.2221 32.7228 64.2643 29.3761 58.6265 26.9605C52.9887 24.545 46.9465 23.3361 40.4998 23.3339C34.0532 23.3317 28.0121 24.5405 22.3765 26.9605C16.741 29.3805 11.7821 32.7272 7.49984 37.0005Z" fill="#2D0077"/>
                <path d="M36.8597 66.5903C37.8508 67.5814 39.0642 68.077 40.4997 68.077C41.9353 68.0725 43.1486 67.577 44.1397 66.5903C45.1308 65.6036 45.6264 64.3903 45.6264 62.9503C45.6264 61.5103 45.1308 60.2959 44.1397 59.307C43.1486 58.3181 41.9353 57.8225 40.4997 57.8203C39.0642 57.8181 37.8508 58.3137 36.8597 59.307C35.8686 60.3003 35.373 61.5148 35.373 62.9503C35.373 64.3859 35.8686 65.5992 36.8597 66.5903Z" fill="url(#paint0_linear_4295_57851)"/>
                <defs>
                <linearGradient id="paint0_linear_4295_57851" x1="35.373" y1="62.9486" x2="45.6264" y2="62.9486" gradientUnits="userSpaceOnUse">
                <stop stop-color="#FF1564"/>
                <stop offset="1" stop-color="#FD7328"/>
                </linearGradient>
                </defs>
            </svg>
            <h3>{{ $t('wat_focus_connectivity_network') }}</h3>
            <div class="WatFocusConnectivityTest--NetworkData">
                <div>
                    <p>{{ networkData.download }}</p>
                    <p>{{ $t('wat_focus_connectivity_download') }}</p>
                </div>
                <div>
                    <p>{{ networkData.upload }}</p>
                    <p>{{ $t('wat_focus_connectivity_upload') }}</p>
                </div>
            </div>
            <button v-on:click="testNetwork()">
                {{ networkButtonText }}
            </button>
        </div>
        <div class="WatFocusConnectivityTest--Test" v-if="currentStep == 5">
            <svg width="81" height="80" viewBox="0 0 81 80" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M47.166 69.0833V62.25C52.166 60.8056 56.1938 58.0278 59.2494 53.9167C62.3049 49.8056 63.8327 45.1389 63.8327 39.9167C63.8327 34.6944 62.3049 30.0278 59.2494 25.9167C56.1938 21.8056 52.166 19.0278 47.166 17.5833V10.75C54.0549 12.3056 59.666 15.7922 63.9993 21.21C68.3327 26.6278 70.4994 32.8633 70.4994 39.9167C70.4994 46.97 68.3327 53.2067 63.9993 58.6267C59.666 64.0467 54.0549 67.5322 47.166 69.0833ZM47.166 53.3333V26.5C49.7771 27.7222 51.8194 29.5556 53.2927 32C54.766 34.4444 55.5016 37.1111 55.4994 40C55.4994 42.8333 54.7627 45.4589 53.2893 47.8767C51.816 50.2944 49.7749 52.1133 47.166 53.3333Z" fill="url(#paint0_linear_4295_57902)"/>
                <path d="M10.5 29.9997V49.9997H23.8333L40.5 66.6663V13.333L23.8333 29.9997H10.5Z" fill="#2D0077"/>
                <defs>
                <linearGradient id="paint0_linear_4295_57902" x1="47.166" y1="39.9167" x2="70.4994" y2="39.9167" gradientUnits="userSpaceOnUse">
                <stop stop-color="#FF1564"/>
                <stop offset="1" stop-color="#FD7328"/>
                </linearGradient>
                </defs>
            </svg>
            <h3>{{ $t('wat_focus_connectivity_volume') }}</h3>
            <button v-on:click="testAudio()">
                {{ $t('wat_focus_connectivity_button_audio') }}
            </button>
            <audio ref="audioElement" :src="audioSrc" style="position: absolute; opacity: 0.01;" controls></audio>
        </div>
        <button class="WatFocusConnectivityTest--ContinueButton" :disabled="error" v-on:click="nextStep()">
            {{ buttonText }}
        </button>
    </div>
  </template>
  
  <script>
  import { defineComponent } from 'vue'
  import Swal from "sweetalert2";
  import { requestCameraPermission, requestMicrophonePermission, getUserCameraData } from '../../utils/MobilePermissions'

  export default defineComponent({
    name:'WatFocusConnectivityTest',
    props:{
        testTile:{
            type: String,
            default: 'WATChanceFocus | <span>Lanzamiento nueva App</span>'
        },
        showScreenShare:{
            type: Boolean,
            default: false
        },
        isNativePlatform:{
            type: Boolean,
            default: false
        },
        isAndroid:{
            type: Boolean,
            default: false
        }
    },
    data: () => {
      return {
        currentStep:1,
        microVolume:0,
        microStream:null,
        cameraStream:null,
        audioSrc:null,
        networkData:{
            upload: 0,
            download:0
        },
        shareScreenText:"",
        networkButtonText:"",
        devices:{
            camera:{
                name:'',
                id:0
            },
            micro:{
                name:'',
                id:0
            },
            speaker:{
                name:'',
                id:0
            }
        },
        testErrors:{
            micro:false,
            audio: false,
            camera:false,
            network:false,
            screen:false
        },
        error: true
      };
    },
    computed:{
        buttonText(){
            return this.currentStep == 5 ? this.$t('wat_focus_connectivity_complete') : this.$t('wat_focus_connectivity_next')
        }
    },
    methods: {
        showError(errorText){
            Swal.fire("",errorText,"error")
        },
        nextStep(){
            this.error = true
            if(this.currentStep == 1) this.saveStep1()
            else if(this.currentStep == 2) this.saveStep2()
            else if(this.currentStep == 3) this.saveStep3()
            else if(this.currentStep == 4) this.saveStep4()
            else if(this.currentStep == 5) this.saveStep5()

        },
        saveStep1(){
            this.currentStep++
            this.microStream.getTracks().forEach(track => track.stop());
            this.testCamera()
        },
        saveStep2(){
            if(!this.showScreenShare || this.isMobileDevice()) this.currentStep += 2;
            else this.currentStep++
            this.cameraStream.getTracks().forEach(track => track.stop());
        },
        saveStep3(){
            this.currentStep++

        },
        saveStep4(){
            this.currentStep++

        },
        saveStep5(){
            this.currentStep++
            const data = {
                cameraDevice: this.devices.camera.name,
                microphoneDevice: this.devices.micro.name,
                networkData: this.networkData,
                audioWorking: true,
                videoWorking: true
            }
            this.$emit('completed', data)
        },
        async testMicrophone() {
            if(this.isAndroid){
                const granted = await requestMicrophonePermission()
                if(!granted) {
                    this.performMicroError()
                }
                else{
                    this.testErrors.micro = false
                    this.error = false
                }
            }
            else{
                const vm = this
                await navigator.mediaDevices.getUserMedia({ audio: true }).then(function(stream) {
                    vm.microStream = stream
                    const audioContext = new (window.AudioContext || window.webkitAudioContext)();
                    const source = audioContext.createMediaStreamSource(stream);
                    const analyser = audioContext.createAnalyser();

                    source.connect(analyser);
                    analyser.fftSize = 256;

                    const bufferLength = analyser.frequencyBinCount;
                    const dataArray = new Uint8Array(bufferLength);
                    const detectarSonido = () => {
                        analyser.getByteFrequencyData(dataArray);
                        const averageVolume = dataArray.reduce((a, b) => a + b) / bufferLength;
                        vm.microVolume = (averageVolume / 255) * 500;
                    };

                    setInterval(detectarSonido, 200);
                    let audioTrack = stream.getAudioTracks()[0];
                    vm.devices.micro.id = audioTrack.getSettings().deviceId;            
                    return navigator.mediaDevices.enumerateDevices();
                    
                }).then(function(devices) {
                    devices.forEach(function(device) {
                        if (device.kind === "audioinput") {
                            if (device.deviceId === vm.devices.micro.id) {
                                vm.devices.micro.name = device.label
                            }
                        }
                    });
                    vm.testErrors.micro = false
                    vm.error = false
                }).catch(function(err) {
                    vm.performMicroError()
                });
            }
        },
        async testCamera() {
            const vm = this
            if(this.isAndroid){
                const granted = await requestCameraPermission()
                if(!granted){
                    this.performCameraError()
                    return
                }
                else {
                    this.error = false
                    this.testErrors.camera = false
                    return
                }
                const cameraData = await getUserCameraData()
                if(cameraData.error) {
                    this.performCameraError()
                    return
                }

                const video = this.$refs.video
                video.srcObject = cameraData.cameraSrc
                video.play()
                this.devices.camera.id = cameraData.cameraDeviceId
                this.devices.camera.name = cameraData.cameraDeviceName

                this.error = false
                this.testErrors.camera = false
                return
            }

            await navigator.mediaDevices.getUserMedia({ video: true })
            .then(function(stream) {
                vm.cameraStream = stream
                const video = vm.$refs.video 
                video.srcObject = stream
                video.play()
                let videoTrack = stream.getVideoTracks()[0];
                vm.devices.camera.id = videoTrack.getSettings().deviceId;            
                return navigator.mediaDevices.enumerateDevices();
            })
            .then(function(devices) {
                devices.forEach(function(device) {
                    if (device.kind === "videoinput") {
                        if (device.deviceId === vm.devices.camera.id) {
                            vm.devices.camera.name = device.label
                        }
                    }
                });
                vm.error = false
                vm.testErrors.camera = false
            }).catch(function(err) {
                vm.performCameraError()
            });
        },
        performMicroError(){
            this.error = true
            this.testErrors.micro = true
            this.showError(this.$t('wat_focus_connectivity_error_micro'))
        },
        performCameraError(){
            this.error = true
            this.testErrors.camera = true
            this.showError(this.$t('wat_focus_connectivity_error_camera'))
        },
        async shareScreen() {
            this.shareScreenText = await this.$t('wat_focus_connectivity_button_sharing')
            try {
                const stream = await navigator.mediaDevices.getDisplayMedia();
                stream.getTracks().forEach(track => track.stop());
                this.shareScreenText = this.$t('wat_focus_connectivity_button_finished')
                this.error = false
                this.testErrors.screen = false
            } catch (error) {
                this.error = true
                this.testErrors.screen = true
                this.shareScreenText = this.$t('wat_focus_connectivity_button_share')
                this.showError(this.$t('wat_focus_connectivity_error_screen'))
            }
        },
        async testNetwork() {
            this.networkButtonText = await this.$t('wat_focus_connectivity_button_network_loading')
            let vm = this
            try {
                const uploadUrl =  process.env.VUE_APP_API_URL + "/watFocus/connection";
                const uploadSizeBytes = 5000000;
                const uploadData = new Uint8Array(uploadSizeBytes);

                const uploadStartTime = Date.now();
                await fetch(uploadUrl, {
                    method: 'POST',
                    body: uploadData,
                    headers: {
                        'Content-Type': 'application/octet-stream'
                    }
                });
                const uploadEndTime = Date.now();

                const uploadDuration = (uploadEndTime - uploadStartTime) / 1000;
                const uploadSpeedMbps = (uploadSizeBytes * 8) / (uploadDuration * 1024 * 1024);

                vm.networkData.upload = uploadSpeedMbps.toFixed(2)

                const downloadUrl = 'https://wat-files-dev.s3.eu-central-1.amazonaws.com/wat_focus_download_image.jpg';
                const downloadSizeBytes = 5000000;

                const startTime = new Date().getTime();
  
                const img = new Image();
                img.src = downloadUrl + "?cache=" + Math.random();
                img.onload = function() {
                    const endTime = new Date().getTime();
                    const duration = (endTime - startTime) / 1000;
                    const bitsLoaded = downloadSizeBytes * 8;
                    const speedMbps = (bitsLoaded / duration) / (1024 * 1024);
                    vm.networkData.download = speedMbps.toFixed(2)
                    vm.networkButtonText = vm.$t('wat_focus_connectivity_button_network_finished')
                    vm.error = false
                    vm.testErrors.network = false
                };
            } catch (error) {
                this.error = true
                this.testErrors.network = true
                vm.networkButtonText = vm.$t('wat_focus_connectivity_button_network')
                vm.showError(vm.$t('wat_focus_connectivity_error_network'))
            }
        },
        async testAudio() {
            try {
                const context = new (window.AudioContext || window.webkitAudioContext)();
        
                if (context.state === 'suspended') {
                    await context.resume();
                }

                const oscillator = context.createOscillator();
                oscillator.type = 'sine';
                oscillator.frequency.setValueAtTime(440, context.currentTime);
                oscillator.connect(context.destination);
                
                const source = context.createMediaStreamDestination();
                oscillator.connect(source);

                oscillator.start();
                oscillator.stop(context.currentTime + 1);

                const audioElement = this.$refs.audioElement;
                audioElement.srcObject = source.stream;

                await audioElement.play();

                this.error = false;
                this.testErrors.audio = false;
            } catch (error) {
                this.error = true;
                this.testErrors.audio = true;
                this.showError(this.$t('wat_focus_connectivity_error_audio'));
            }
        },
        isMobileDevice(){
            return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
        }
    },
    mounted(){
        this.shareScreenText = this.$t('wat_focus_connectivity_button_share')
        this.networkButtonText = this.$t('wat_focus_connectivity_button_network')
        this.testMicrophone()
    }
  });
  </script>
  <style lang="scss" scoped>
  .WatFocusConnectivityTest{
    font-family: Lato;
    background: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 50px;
    padding: 16px;
    border-radius: 8px;
    width: 700px;
    height: 500px;
    video{
        width: 300px;
        height: 160px;
        background: black;
        border-radius: 8px;
    }
    p{
        font-size: 14px;
        font-weight: 700;
        margin-bottom:0;
    }
    h3{
        font-weight: 700;
        font-size: 24px;
    }
    &--Steps{
        display: flex;
        margin-left: 28px;
    }
    &--Step{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 36px;
        height: 36px;
        border-radius: 36px;
        position: relative;
        background:#EAEAEA;
        margin-right:28px;
        &:last-child::before{
            display: none;
        }
        &:before{
            content:"";
            width: 28px;
            height:2px;
            position: absolute;
            top: calc(50% - 2px);
            background:#EAEAEA;
            right: -28px;
        }
        &.completed, &.completed:before{
            background: #96C800;
        }
        &.active{
            background:#8134FF;
        }
    }
    &--ContinueButton{
        padding: 16px;
        border-radius: 100px;
        background: #96C800;
        color: white;
        border: none;
        &:disabled{
            opacity: 0.5;
        }
    }
    &--Test{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 350px;
        button{
            height: 48px;
            padding: 8px 16px;
            border-radius: 4px;
            border: 1px solid #EAEAEA;
            background: transparent;
        }
        h4{
            font-size: 12px;
        }
    }
    &--NetworkData{
        display: flex;
        gap: 15px;
        margin-bottom: 20px;
        div{
            display: flex;
            flex-direction: column;
            align-items: center;
            p:nth-child(1){
                color: #96C800;
                font-family: Bariol;
                font-size: 27px;
                font-weight: 700;
            }
            p:nth-child(2){
                font-family: Lato;
                font-size: 10px;
                font-weight: 700;
                color: #A6A6A6;
            }
        }
    }
    &--Volume{
        display: flex;
        gap: 5px;
        span{
            height: 30px;
            width: 11px;
            background: #EAEAEA;
            transition: 0.2s;
            border-radius: 20px;
            &.active{
                background: #96C800;
            }
        }
    }
    button {
        cursor: pointer;
    }
  }
    </style>
   